.contact-us {
  &__top {
    max-width: 1240px;
    margin: 0 auto;
    padding: 0 30px;
    display: flex;

    @media screen and (max-width: 992px) {
      flex-direction: column;
    }

    h3 {
      color: #be2c2c;
      font-family: "Rubik", sans-serif;
      font-size: 36px;
      text-transform: uppercase;
      border-bottom: 1px solid black;
      letter-spacing: -2px;
      margin: 20px 0;
    }

    &--left {
      @media screen and (min-width: 992px) {
        margin-right: 50px;
      }

      .hours {
        &--info {
          p {
            display: flex;
            justify-content: space-between;
            font-size: 20px;
            letter-spacing: -1px;

            &:not(:last-child) {
              margin-bottom: 10px;
            }
          }
        }
      }

      .address {
        a {
          color: black;
        }
      }

      .call {
        a {
          color: black;
          text-decoration: none;
          font-size: 16px;
        }
      }
    }

    &--right {
      .input-container {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;

        &__name,
        &__contact {
          flex: 1 1 auto;
        }

        &__message {
          width: 100%;
        }

        &__name,
        &__contact,
        &__message {
          label {
            font-size: 14px;
          }

          input,
          textarea {
            border: none;
            width: 100%;
            background-color: #e5e5e5;
            padding: 10px 15px;
            border-radius: 5px;
            resize: none;
          }
        }

        .submit {
          margin-left: auto;
          display: block;
          background-color: #3751dc;
          color: white;
          border: none;
          padding: 15px 35px;
          font-size: 16px;
          border-radius: 15px;
          text-transform: uppercase;
          font-family: "Rubik", sans-serif;
          cursor: pointer;
          transition: all 0.15s ease-in-out;

          &.Sent {
            background-color: black;
          }

          &:hover {
            background-color: #be2c2c;
            transition: all 0.15s ease-in-out;
          }
        }
      }
    }
  }

  &__google-map {
    margin-bottom: -6px;

    iframe {
      width: 100vw;
      height: 500px;
      margin-top: 50px;
    }
  }
}
