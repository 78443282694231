.home {
  &__overlay,
  &__header-text {
    width: 100%;
    height: calc(100vh - 155px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__overlay {
    background: url(../../assets/main_image.jpg);
    background-size: cover;
  }

  &__header-text {
    color: white;
    background-color: rgba(0, 0, 0, 0.3);
    text-align: center;

    h1,
    h2 {
      font-family: 'Rubik', sans-serif;
    }
    
    h1 {
      font-size: 48px;
      margin: 0;
    }

    h2 {
      font-size: 24px;
      margin: 20px 0;
    }

    button {
      font-family: 'Roboto', sans-serif;
      border: none;
      padding: 15px 25px;
      background-color: #BE2C2C;
      color: white;
      font-weight: 500;
      text-transform: uppercase;
      letter-spacing: 5px;
      font-size: 12px;
      cursor: pointer;
      transition: all 0.15s ease-in-out;

      &:hover {
        background-color: #3751DC;
        transition: all 0.15s ease-in-out;
      }
    }
  }
}
