.schedule {
  main {
    text-align: center;
    margin: 20px 0;

    img {
      width: 100%;
      max-width: 1240px;
    }
  }
}