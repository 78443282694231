.about {
  main {
    background-color: black;
    display: flex;
    justify-content: center;

    @media screen and (max-width: 992px) {
      flex-direction: column;
      align-items: center;
    }

    .image-container {
      width: 100%;
      max-width: 800px;

      @media screen and (min-width: 992px) {
        max-width: 500px;
      }
    }
    
    .image-container {
      img {
        width: 100%;
        min-height: 400px;
        object-fit: cover;

        @media screen and (min-width: 992px) {
          max-width: 500px;
        }
      }
    }

    section {
      background-color: white;
      padding: 30px;
      max-width: 800px;

      .bio-container {
        h2 {
          font-family: 'Rubik', sans-serif;
          font-size: 40px;
          text-transform: uppercase;
          border-bottom: 1px solid black;
          letter-spacing: -2px;
        }

        p {
          padding: 30px 0;
          line-height: 30px;
          border-bottom: 1px solid black;
          margin-bottom: 30px;
        }
      }

      ul li {
        list-style: none;
        margin-bottom: 12px;

        .year {
          font-weight: 800;
          font-size: 18px;
          display: block;
          margin-bottom: 4px;
          color: #BE2C2C;
          letter-spacing: -1px;
        }

        p {
          font-size: 15px;
          font-style: italic;
        }
      }
    }
  }
}