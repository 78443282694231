.navbar {
  max-width: 1240px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;

  &__logo {
    display: flex;
    align-items: center;

    .logo {
    max-width: 320px;

      @media screen and (max-width: 992px) {
        max-width: 220px;
      }
    }
  }
  
  &__menu {
    display: flex;
    align-items: center;

    .nav-item {
      display: flex;
      gap: 40px;
      padding: 0;
      margin: 0;

      @media screen and (max-width: 992px) {
        flex-direction: column;
        background-color: white;
        gap: 30px;
        width: 100%;
        border-radius: 10px;
        padding: 35px;
      }

      a {
        list-style: none;
        text-transform: uppercase;
        font-family: 'Rubik', sans-serif;
        font-weight: 600;
        text-decoration: none;
        color: black;
        display: inline-block;
        padding: 0 !important;

        @media screen and (max-width: 992px) {
          width: 100%;
        }
      }
    }
  }
}