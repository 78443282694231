  .our-programs {
    header {
      background-color: black;

    h1 {
      color: white;
      padding: 60px 20px;
      font-family: 'Rubik', sans-serif;
      text-transform: uppercase;
      text-align: center;
      letter-spacing: 7px;
    }
  }
  
  &__card-container {
    display: flex;
    justify-content: center;
    gap: 50px;
    text-align: center;
    margin: 60px 0;
    padding: 0 20px;

    @media screen and (max-width: 992px) {
      flex-direction: column;
    }

    .program-card {
      width: 300px;
      background-color: #F5F5F5;
      padding: 20px;
      display: flex;
      flex-direction: column;
      box-shadow: 10px 10px 10px #0000001a;

      @media screen and (max-width: 992px) {
        width: 100%;
      }

      h2 {
        font-family: 'Rubik', sans-serif;
        margin: 20px 0;
        border-bottom: 2px solid #BE2C2C;
        padding-bottom: 5px;
      }

      img {
        width: 100%;
        border-radius: 10px;
      }

      section{
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        p {
          line-height: 24px;
        }

        button {
          border: none;
          padding: 15px 25px;
          background-color: #3751DC;
          color: white;
          font-weight: 500;
          text-transform: uppercase;
          letter-spacing: 5px;
          font-size: 12px;
        }
      }
    }
  }
}