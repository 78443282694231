footer {
  background-color: rgba(0, 0, 0, 0.8);

  .footer-container {
    padding: 50px 0;
    max-width: 1240px;
    margin: 0 auto;
    position: relative;
    z-index: 1;
    color: white;
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 992px) {
      flex-direction: column;
      margin: 0 40px;
    }

    &::before {
      content: "";
      position: absolute;
      top: 0; 
      left: 0;
      width: 100%; 
      height: 100%;  
      opacity: .6; 
      z-index: -1;
      background: url(../../assets/logo-footer.png);
      background-size: 300px;
      background-repeat: no-repeat;
      background-position: center;

      @media screen and (max-width: 992px) {
        opacity: .3;
      }
    }

    .footer-menu {
      &__left {
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @media screen and (max-width: 992px) {
          order: 2;
        }

        ul {
          padding: 0;

          li {
            list-style: none;

            a {
              text-decoration: none;
              color: white;
              font-size: 14px;

              &:hover {
                text-decoration: underline;
              }
            }

            &:not(:last-child) {
              margin-bottom: 15px;
            }
          }
        }

        .copyright {
          font-size: 12px;
        }
      }

      &__right {
        text-align: right;

        @media screen and (max-width: 992px) {
          text-align: left;
          margin-bottom: 40px;
        }

        h2,
        h3 {
          font-family: 'Rubik', sans-serif;
          margin: 10px 0;
        }

        @media screen and (max-width: 992px) {
          h3 {
            margin-top: 40px;
          }
        }

        p {
          font-size: 14px;
          line-height: 30px;
          margin: 0;

          a {
            color: white;
            text-decoration: none;
          }

          a:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}