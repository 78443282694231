.promobar {
  background-color: #9D2424;

  &__text {
    max-width: 1240px;
    margin: 0 auto;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 14px;
    padding: 10px 30px;
    color: white;

    a {
      color: white;

      &:hover {
        color: white;
      }
    }
  }
}